import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const SpendGuidePage = () => (
  <Layout>
    <SEO title="Spend Guide" />
    <section className="intro">
      <div className="intro-container">
        <h2>Spend Guide</h2>
        <p>Which <strong>kink events</strong> and <strong>fetish vendors</strong> are committed to <strong>inclusion</strong>, <strong>representation</strong>, <strong>equity</strong>, and <strong>safety</strong>? Use my guide to inform how and where you spend your hard-earned money.</p>
      </div>
    </section>
    <section className="content">
      <div className="content-container">
        <p>My <strong>Spend Guide</strong> evaluates and identifies kink events and fetish vendors that may or may not practice, preach, and promote antiracism, inclusion, equitable representation, and safety. I designed it primarily for myself, but it may be useful for Black, Indigenous, and people of color (BIPOC); trans, non-binary, and gender non-conforming folx; women; disabled people; and other marginalized folx. Use my handy guide to inform how and where you spend your hard-earned money. To add events or vendors to this spend guide, <Link to="/contact">contact me</Link>.</p>
        <p>Inspired by the <a href="https://afro.com/black-women-launching-digital-green-book-for-black-travelers/"><em>Digital Green Book</em></a> (<a href="https://www.gofundme.com/f/digital-green-book-website">support their GoFundMe</a>).</p>
        <p>Jump to:</p>
        <ul>
          <li><a href="#events-spend-guide">Events spend guide</a></li>
          <li><a href="#vendors-spend-guide">Vendors spend guide</a></li>
        </ul>
        <h3 id="events-spend-guide">Events spend guide</h3>
        <p>The events spend guide adheres to the rubric below. As with all rubrics of this nature, it is flawed and unscientific. </p>
        <ul>
          <li><strong>Incident count (40%).</strong> Based on reported incidents of anti-Blackness, racism, transphobia, misogyny, and ableism over the last five years (2016–2020) in <em>official</em> event spaces (40%). Each reported incident of abuse, assault, or harassment reduces an event's score by 10% until the 40% is exhausted. In other words, are the event's policies (if any) being <em>enforced</em>? To file an anonymous report about an incident, <Link to="/contact">contact me</Link>.</li>
          <li><strong>Publicly posted policies (40%).</strong> Based on a publicly available code of conduct (20%), anti-harassment policy (10%), and clear enforcement information (10%). Policies must be publicly available before the attendee registers or enters the event. Attendees must be able to have their incident reports addressed discreetly, in real time, and without any delay. In other words, are there clear and publicly available <em>policies</em>? To file an anonymous report about publicly posted policies, <Link to="/contact">contact me</Link>.</li>
          <li><strong>Equitable representation (20%).</strong> Based on an event's representation of underrepresented groups in online event marketing (5%), in event staff and volunteers (5%), and in event leadership (10%). For non-profit organizations, representation in event leadership means representation of underrepresented groups in the executive team or steering committee. For for-profit organizations, representation in event leadership means representation of underrepresented groups in financial stewardship with an ownership stake or equitable paid compensation. In other words, are marginalized groups equitably <em>represented</em> by events? To file an anonymous report about equitable representation, <Link to="/contact">contact me</Link>.</li>
        </ul>
        <p>If you have feedback about this rubric, or if you'd like to hire me to help you with risk management, <Link to="/contact">contact me</Link>.</p>
        <table className="guide">
          <thead>
            <tr>
              <th>Event</th>
              <th>Score</th>
              <th>Anti-Black incident reported?</th>
              <th>Racist incident reported?</th>
              <th>Transphobic incident reported?</th>
              <th>Misogynistic incident reported?</th>
              <th>Ableist incident reported?</th>
              <th>Public code of conduct?</th>
              <th>Public anti-harassment policy?</th>
              <th>Public enforce info?</th>
              <th>Rep. in marketing?</th>
              <th>Rep. in staff?</th>
              <th>Rep. in leadership?</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td><a href="https://www.americanbrotherhood.com" rel="noreferrer" target="_blank">American Brotherhood Weekend (ABW)</a></td>
              <td><strong>D–</strong> (60%)</td>
              <td>0</td>
              <td>0</td>
              <td>0</td>
              <td>0</td>
              <td>0</td>
              <td>No</td>
              <td>No</td>
              <td>No</td>
              <td>Yes</td>
              <td>Yes</td>
              <td>Yes</td>
            </tr>
            <tr>
              <td><a href="https://clawinfo.org" rel="noreferrer" target="_blank">Cleveland Leather Annual Weekend (CLAW)</a></td>
              <td><strong>F</strong> (50%)</td>
              <td>0</td>
              <td>0</td>
              <td>1</td>
              <td>1</td>
              <td>0</td>
              <td><a href="https://clawinfo.org/home/about-claw/code-of-conduct" rel="noreferrer" target="_blank">Yes</a></td>
              <td>No</td>
              <td>No</td>
              <td>Yes</td>
              <td>Yes</td>
              <td>No</td>
            </tr>
            <tr>
              <td><a href="https://darklands.be" rel="noreferrer" target="_blank">Darklands (Leatherpride Belgium)</a></td>
              <td><strong>F</strong> (40%)</td>
              <td>0</td>
              <td>0</td>
              <td>0</td>
              <td>0</td>
              <td>0</td>
              <td>No</td>
              <td>No</td>
              <td>No</td>
              <td>No</td>
              <td>No</td>
              <td>No</td>
            </tr>
            <tr>
              <td><a href="https://easterberlin.de/en/" rel="noreferrer" target="_blank">Easter in Berlin</a></td>
              <td><strong>F</strong> (40%)</td>
              <td>0</td>
              <td>0</td>
              <td>0</td>
              <td>0</td>
              <td>0</td>
              <td>No</td>
              <td>No</td>
              <td>No</td>
              <td>No</td>
              <td>No</td>
              <td>No</td>
            </tr>
            <tr>
              <td><a href="https://www.fetishweek.com" rel="noreferrer" target="_blank">Fetish Week London</a></td>
              <td><strong>D–</strong> (60%)</td>
              <td>0</td>
              <td>0</td>
              <td>0</td>
              <td>0</td>
              <td>0</td>
              <td>No</td>
              <td>No</td>
              <td>No</td>
              <td>Yes</td>
              <td>Yes</td>
              <td>Yes</td>
            </tr>
            <tr>
              <td><a href="https://folsomeurope.berlin" rel="noreferrer" target="_blank">Folsom Europe Berlin</a></td>
              <td><strong>F</strong> (20%)</td>
              <td>0</td>
              <td>1</td>
              <td>1</td>
              <td>1</td>
              <td>0</td>
              <td>No</td>
              <td>No</td>
              <td>No</td>
              <td>Yes</td>
              <td>Yes</td>
              <td>No</td>
            </tr>
            <tr>
              <td><a href="http://www.folsomstreeteast.com" rel="noreferrer" target="_blank">Folsom Street East</a></td>
              <td><strong>D–</strong> (60%)</td>
              <td>0</td>
              <td>0</td>
              <td>0</td>
              <td>0</td>
              <td>0</td>
              <td>No</td>
              <td>No</td>
              <td>No</td>
              <td>Yes</td>
              <td>Yes</td>
              <td>Yes</td>
            </tr>
            <tr>
              <td><a href="https://www.folsomstreetevents.org" rel="noreferrer" target="_blank">Folsom Street Events</a></td>
              <td><strong>D–</strong> (60%)</td>
              <td>0</td>
              <td>0</td>
              <td>0</td>
              <td>0</td>
              <td>0</td>
              <td>No</td>
              <td>No</td>
              <td>No</td>
              <td>Yes</td>
              <td>Yes</td>
              <td>Yes</td>
            </tr>
            <tr>
              <td><a href="http://www.ilsb-icbb.com" rel="noreferrer" target="_blank">International Leather Sir/boy and International Community Bootblack (ILSb/ICBB)</a></td>
              <td><strong>F</strong> (40%)</td>
              <td>1</td>
              <td>0</td>
              <td>0</td>
              <td>0</td>
              <td>0</td>
              <td>No</td>
              <td>No</td>
              <td>No</td>
              <td>Yes</td>
              <td>Yes</td>
              <td>No</td>
            </tr>
            <tr>
              <td><a href="https://imrl.org" rel="noreferrer" target="_blank">International Mr. Leather and Bootblack (IMLBB)</a></td>
              <td><strong>F</strong> (50%)</td>
              <td>0</td>
              <td>0</td>
              <td>0</td>
              <td>1</td>
              <td>0</td>
              <td>No</td>
              <td>No</td>
              <td>No</td>
              <td>Yes</td>
              <td>Yes</td>
              <td>Yes</td>
            </tr>
            <tr>
              <td><a href="https://imslbb.org" rel="noreferrer" target="_blank">International Ms. Leather and Bootblack (IMsLBB)</a></td>
              <td><strong>D–</strong> (60%)</td>
              <td>0</td>
              <td>0</td>
              <td>0</td>
              <td>0</td>
              <td>0</td>
              <td>No</td>
              <td>No</td>
              <td>No</td>
              <td>Yes</td>
              <td>Yes</td>
              <td>Yes</td>
            </tr>
            <tr>
              <td><a href="https://www.ipahw.com" rel="noreferrer" target="_blank">International Pup and Handler Weekend (IPAHW)</a>*</td>
              <td><strong>A–</strong> (90%)</td>
              <td>0</td>
              <td>0</td>
              <td>0</td>
              <td>0</td>
              <td>0</td>
              <td><a href="https://www.ipahw.com/diversity-accessibility" rel="noreferrer" target="_blank">Yes</a></td>
              <td>No</td>
              <td><a href="https://www.ipahw.com/diversity-accessibility" rel="noreferrer" target="_blank">Yes</a></td>
              <td>Yes</td>
              <td>Yes</td>
              <td>Yes</td>
            </tr>
            <tr>
              <td><a href="http://www.manchester-rubber.co.uk" rel="noreferrer" target="_blank">Manchester Rubber Weekend</a></td>
              <td className="guide-score"><strong>D–</strong> (60%)</td>
              <td>0</td>
              <td>0</td>
              <td>0</td>
              <td>0</td>
              <td>0</td>
              <td>No</td>
              <td>No</td>
              <td>No</td>
              <td>Yes</td>
              <td>Yes</td>
              <td>No</td>
            </tr>
            <tr>
              <td><a href="https://www.leatherweekend.com" rel="noreferrer" target="_blank">Mid-Atlantic Leather Weekend (MAL)</a></td>
              <td><strong>F</strong> (40%)</td>
              <td>0</td>
              <td>1</td>
              <td>0</td>
              <td>0</td>
              <td>0</td>
              <td>No</td>
              <td>No</td>
              <td>No</td>
              <td>Yes</td>
              <td>Yes</td>
              <td>No</td>
            </tr>
            <tr>
              <td><a href="https://mirubber.com" rel="noreferrer" target="_blank">Mr. International Rubber (MIR)</a></td>
              <td><strong>A–</strong> (90%)</td>
              <td>0</td>
              <td>0</td>
              <td>0</td>
              <td>1</td>
              <td>0</td>
              <td><a href="https://mirubber.com/about/code-of-conduct/" rel="noreferrer" target="_blank">Yes</a></td>
              <td><a href="https://mirubber.com/about/code-of-conduct/" rel="noreferrer" target="_blank">Yes</a></td>
              <td><a href="https://mirubber.com/about/code-of-conduct/" rel="noreferrer" target="_blank">Yes</a></td>
              <td>Yes</td>
              <td>Yes</td>
              <td>Yes</td>
            </tr>
            <tr>
              <td><a href="https://www.olympusleather.com" rel="noreferrer" target="_blank">Olympus Leather Weekend</a></td>
              <td className="guide-score"><strong>D–</strong> (60%)</td>
              <td>0</td>
              <td>0</td>
              <td>0</td>
              <td>0</td>
              <td>0</td>
              <td>No</td>
              <td>No</td>
              <td>No</td>
              <td>Yes</td>
              <td>Yes</td>
              <td>Yes</td>
            </tr>
          </tbody>
        </table>
        <p><small>* This event has faced reports of financial impropriety, but this spend guide does not evaluate events based on financial decision-making.</small></p>
        <h3 id="vendors-spend-guide">Vendors spend guide</h3>
        <p>The vendors spend guide rubric is coming soon. In the meantime, here is a list of vendors owned by marginalized folx.</p>
        <ul>
          <li><a href="https://armyofmen.com" rel="noreferrer" target="_blank">Army of Men</a></li>
          <li><a href="http://www.atsukokudo.com" rel="noreferrer" target="_blank">Atsuko Kudo Couture Latex Design</a></li>
          <li><a href="https://bestdressedlatex.wixsite.com/bestdressedlatex" rel="noreferrer" target="_blank">Best Dressed Latex</a></li>
          <li><a href="https://dawnamatrix.com" rel="noreferrer" target="_blank">Dawnamatrix</a></li>
          <li><a href="https://www.eagleleather.com.au" rel="noreferrer" target="_blank">Eagle Leather</a></li>
          <li><a href="https://www.early2bed.com" rel="noreferrer" target="_blank">Early to Bed</a></li>
          <li><a href="https://vexclothing.com" rel="noreferrer" target="_blank">Vex Clothing</a></li>
        </ul>
        <p>Also, here is a list of vendors who have representation of marginalized folx in their product catalogues.</p>
        <ul>
          <li><a href="https://fetishdaddy.co.uk/" rel="noreferrer" target="_blank">Fetish Daddy Gear</a></li>
          <li><a href="https://invinciblerubber.com/" rel="noreferrer" target="_blank">Invincible Rubber</a></li>
          <li><a href="https://libidex.com/" rel="noreferrer" target="_blank">Libidex</a></li>
          <li><a href="https://www.misterb.com/en/amsterdam" rel="noreferrer" target="_blank">Mister B Amsterdam</a></li>
          <li><a href="https://mr-s-leather.com/" rel="noreferrer" target="_blank">Mr. S Leather</a></li>
          <li><a href="https://polymorphe.com/" rel="noreferrer" target="_blank">Polymorphe</a></li>
        </ul>
        <p>Thanks to <a href="https://twitter.com/aznboundjock" rel="noreferrer" target="_blank">@aznboundjock</a> (NSFW) for the suggestion to list vendors owned by marginalized folx. To add a vendor to this list, <Link to="/contact">contact me</Link>.</p>
        <p><Link to="/" className="call-to-action">Go back home</Link></p>
      </div>
    </section>
  </Layout>
)

export default SpendGuidePage
